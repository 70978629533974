// Globals

:root {
  --app-bg: var(--gray1);
  --cmdk-shadow: 0 16px 70px rgb(0 0 0 / 20%);

  --lowContrast: #ffffff;
  --highContrast: #000000;

  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);

  --grayA1: hsla(0, 0%, 0%, 0.012);
  --grayA2: hsla(0, 0%, 0%, 0.027);
  --grayA3: hsla(0, 0%, 0%, 0.047);
  --grayA4: hsla(0, 0%, 0%, 0.071);
  --grayA5: hsla(0, 0%, 0%, 0.09);
  --grayA6: hsla(0, 0%, 0%, 0.114);
  --grayA7: hsla(0, 0%, 0%, 0.141);
  --grayA8: hsla(0, 0%, 0%, 0.22);
  --grayA9: hsla(0, 0%, 0%, 0.439);
  --grayA10: hsla(0, 0%, 0%, 0.478);
  --grayA11: hsla(0, 0%, 0%, 0.565);
  --grayA12: hsla(0, 0%, 0%, 0.91);
}

.dark {
  --app-bg: var(--gray1);

  --lowContrast: #000000;
  --highContrast: #ffffff;

  --gray1: hsl(0, 0%, 8.5%);
  --gray2: hsl(0, 0%, 11%);
  --gray3: hsl(0, 0%, 13.6%);
  --gray4: hsl(0, 0%, 15.8%);
  --gray5: hsl(0, 0%, 17.9%);
  --gray6: hsl(0, 0%, 20.5%);
  --gray7: hsl(0, 0%, 24.3%);
  --gray8: hsl(0, 0%, 31.2%);
  --gray9: hsl(0, 0%, 43.9%);
  --gray10: hsl(0, 0%, 49.4%);
  --gray11: hsl(0, 0%, 62.8%);
  --gray12: hsl(0, 0%, 93%);

  --grayA1: hsla(0, 0%, 100%, 0);
  --grayA2: hsla(0, 0%, 100%, 0.026);
  --grayA3: hsla(0, 0%, 100%, 0.056);
  --grayA4: hsla(0, 0%, 100%, 0.077);
  --grayA5: hsla(0, 0%, 100%, 0.103);
  --grayA6: hsla(0, 0%, 100%, 0.129);
  --grayA7: hsla(0, 0%, 100%, 0.172);
  --grayA8: hsla(0, 0%, 100%, 0.249);
  --grayA9: hsla(0, 0%, 100%, 0.386);
  --grayA10: hsla(0, 0%, 100%, 0.446);
  --grayA11: hsla(0, 0%, 100%, 0.592);
  --grayA12: hsla(0, 0%, 100%, 0.923);
}


// Raycast

.raycast {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;

  [cmdk-root] {
    .dark & {
      background: var(--gray2);
      border: 0;

      &:after {
        content: '';
        background: linear-gradient(
          to right,
          var(--gray6) 20%,
          var(--gray6) 40%,
          var(--gray10) 50%,
          var(--gray10) 55%,
          var(--gray6) 70%,
          var(--gray6) 100%
        );
        z-index: -1;
        position: absolute;
        border-radius: 12px;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        animation: shine 3s ease forwards 0.1s;
        background-size: 200% auto;
      }

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        border-radius: 12px;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        box-shadow: 0 0 0 1px transparent;
        animation: border 1s linear forwards 0.5s;
      }
    }

    kbd {
      font-family: var(--font-sans);
      background: var(--gray3);
      color: var(--gray11);
      height: 20px;
      width: 20px;
      border-radius: 4px;
      padding: 0 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        margin-left: 8px;
      }
    }
  }

  [cmdk-input] {
    font-family: var(--font-sans);
    border: none;
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    outline: none;
    background: var(--bg);
    color: var(--gray12);

    &::placeholder {
      color: var(--gray9);
    }
  }

  .cmdk-raycast-top-shine {
    .dark & {
      background: linear-gradient(
        90deg,
        rgba(56, 189, 248, 0),
        var(--gray5) 20%,
        var(--gray9) 67.19%,
        rgba(236, 72, 153, 0)
      );
      height: 1px;
      position: absolute;
      top: -1px;
      width: 100%;
      z-index: -1;
      opacity: 0;
      animation: showTopShine 0.1s ease forwards 0.2s;
    }
  }

  .cmdk-raycast-loader {
    --loader-color: var(--gray9);
    border: 0;
    width: 100%;
    width: 100%;
    left: 0;
    height: 1px;
    background: var(--gray6);
    position: relative;
    overflow: visible;
    display: block;
    margin-top: 0;
    margin-bottom: 12px;

    &:after {
      content: '';
      width: 50%;
      height: 1px;
      position: absolute;
      background: linear-gradient(90deg, transparent 0%, var(--loader-color) 50%, transparent 100%);
      top: -1px;
      opacity: 0;
      animation-duration: 1.5s;
      animation-delay: 1s;
      animation-timing-function: ease;
      animation-name: loading;
    }
  }

  [cmdk-item] {
    content-visibility: auto;

    align-items: center;
    border-radius: 8px;
    border: 1px solid transparent;
    color: var(--gray12);
    cursor: pointer;
    display: flex;
    font-size: 15px;
    margin-top: 2px;
    gap: 8px;
    height: 48px;
    padding: 0 8px;
    transition-property: none;
    transition: all 150ms ease;
    user-select: none;
    will-change: background, color;

    &[aria-selected='true'] {
      background: var(--gray4);
      color: var(--gray12);
    }

    &[aria-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--gray4);
    }
  }

  [cmdk-raycast-meta] {
    margin-left: auto;
    color: var(--gray11);
    font-size: 13px;
  }

  [cmdk-list] {
    padding: 0 8px;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-padding-block-end: 40px;
    transition: 100ms ease;
    transition-property: height;
    padding-bottom: 40px;
    height: 100%;
  }

  .cmdk-raycast-open-trigger,
  .cmdk-raycast-subcommand-trigger {
    color: var(--gray11);
    padding: 0px 4px 0px 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    height: 28px;
    letter-spacing: -0.25px;
  }

  .cmdk-raycast-open-trigger {
    display: flex;
    align-items: center;
    color: var(--gray12);
  }

  .cmdk-raycast-subcommand-trigger {
    display: flex;
    align-items: center;
    gap: 4px;
    right: 8px;
    bottom: 8px;

    svg {
      width: 15px;
      height: 15px;
    }

    hr {
      height: 100%;
      background: var(--gray6);
      border: 0;
      width: 1px;
    }

    &[aria-expanded='true'],
    &:hover {
      background: var(--gray4);

      kbd {
        background: var(--gray7);
      }
    }
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: var(--gray5);
    margin: 4px 0;
  }

  *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: 15px;
    color: var(--gray10);
    font-weight: 600;
    padding: 10px 8px 8px;
    display: flex;
    align-items: center;
  }

  [cmdk-dialog] {
    z-index: var(--layer-portal);
    position: fixed;
    left: 50%;
    top: var(--page-top);
    transform: translateX(-50%);

    [cmdk] {
      width: 640px;
      transform-origin: center center;
      animation: dialogIn var(--transition-fast) forwards;
    }

    &[data-state='closed'] [cmdk] {
      animation: dialogOut var(--transition-fast) forwards;
    }
  }

  [cmdk-empty] {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    color: var(--gray11);
  }
}

@keyframes loading {
  0% {
    opacity: 0;
    transform: translateX(0);
  }

  50% {
    opacity: 1;
    transform: translateX(100%);
  }

  100% {
    opacity: 0;
    transform: translateX(0);
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
    opacity: 0;
  }
}

@keyframes border {
  to {
    box-shadow: 0 0 0 1px var(--gray6);
  }
}

@keyframes showTopShine {
  to {
    opacity: 1;
  }
}

.raycast-submenu {
  [cmdk-root] {
    display: flex;
    flex-direction: column;
    width: 320px;
    border: 1px solid var(--gray6);
    background: var(--gray2);
    border-radius: 8px;
    max-height: 400px;
  }

  [cmdk-list] {
    padding: 8px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-item] {
    height: 40px;

    cursor: pointer;
    height: 40px;
    border-radius: 8px;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 4px;
    gap: 8px;
    padding: 0 8px;
    color: var(--gray12);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[aria-selected='true'] {
      background: var(--gray5);
      color: var(--gray12);

      .cmdk-raycast-submenu-shortcuts kbd {
        background: var(--gray7);
      }
    }

    &[aria-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    .cmdk-raycast-submenu-shortcuts {
      display: flex;
      margin-left: auto;
      gap: 2px;
    }
  }

  [cmdk-group-heading] {
    text-transform: capitalize;
    font-size: 12px;
    color: var(--gray11);
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 8px;
    margin-left: 4px;
  }

  [cmdk-input] {
    padding: 12px;
    font-family: var(--font-sans);
    border: 0;
    border-top: 1px solid var(--gray6);
    font-size: 13px;
    background: transparent;
    margin-top: auto;
    width: 100%;
    outline: 0;
    border-radius: 0;
  }

  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transform-origin: var(--radix-popover-content-transform-origin);

  &[data-state='open'] {
    animation-name: slideIn;
  }

  &[data-state='closed'] {
    animation-name: slideOut;
  }

  [cmdk-empty] {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    font-size: 15px;
    color: var(--gray11);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: scale(0.96);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.96);
  }
}

@media (max-width: 640px) {
  .raycast {
    [cmdk-input] {
      font-size: 16px;
    }
  }
}

