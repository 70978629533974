
::selection {
  background: #80eec555;
}

html,
body {
  --font-sans: 'Poppins', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  padding: 0;
  margin: 0;
  font-family: var(--font-sans);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5
}

body {
  background: var(--app-bg);
  overflow-x: hidden;
}

button {
  background: none;
  font-family: var(--font-sans);
  padding: 0;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
